*::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  border-radius: 10px;
  background-color: #eeebeb;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #eeebeb;
}

*::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  background-color: #aaabab;
}

.shim-green {
  position: relative;
  overflow: hidden;
  background-color: #ace3cb;
}
.shim-green::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(30, 215, 96, 1) 0,
    rgba(30, 215, 96, 1) 50%,
    rgba(30, 215, 96, 1) 100%
  );
  animation: shimmer 6s ease-out infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(0%);
    opacity: 0;
  }
}
