@tailwind base;
@tailwind components;
@tailwind utilities;
/* @tailwind variants; */

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
  .animation-delay-5000 {
    animation-delay: 5s;
  }
}

@layer base {
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
  .animation-delay-5000 {
    animation-delay: 5s;
  }
}
